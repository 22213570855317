<script setup>
const triggerError = () => {
  throw new Error('Nuxt Test Error Button Error')
  };
</script>

<template>
  <button id="testErrorBtn" variant="link" @click="triggerError">
    e
  </button>
</template>
